import axios from 'axios';
import { IResponse } from '../Dtos/IResponse';
import { getCookie } from '../Utils/cookieUtils';

// Tạo một instance của Axios
const axiosInstance = axios.create({
    baseURL: 'https://api1.pamosa.vn', 
    headers: {
      'Content-Type': 'application/json', 
    },
  });

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Chuyển hướng đến trang đăng nhập nếu nhận được lỗi 401
      window.location.href = '/login';
    }
    else if (error.response) {
      // Chuyển hướng đến trang đăng nhập nếu nhận được lỗi 500
      window.location.href = '/error500';
    }
    return Promise.reject(error);
  }
);


export async function apiGet<T>(url:string, params: any | null){
    const token = getCookie('token')?.toString(); 
   
     const headers = {
       'Authorization' : token
     }
     
     const  {data} = await axiosInstance.get<IResponse<T>>(
       url,
       { params : params,
         headers: headers,
       }
     )
     return data;
  }
  
  
  
  export async function apiPost<T>(url:string, params: any | null){
    const token = getCookie('token')?.toString(); 
   
     const headers = {
       'Authorization' : token
     }
     const  {data}  = await axiosInstance.post<IResponse<T>>(
      url,
      params,
       {
         headers: headers,
       }
     )
     return data;
  }

  export async function apiPostNoneHead<T>(url:string, params: any | null){
     const  {data}  = await axiosInstance.post<IResponse<T>>(
      url,
      params
     )
     return data;
  }


export default axiosInstance;